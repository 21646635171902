import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

/* Images */
import background_1 from '../../img/TCP_background_1.jpg'
import background_2 from '../../img/TCP_background_2.jpg'
import standardy from '../../img/TCP_standardy.jpg'
import transport_chemii from '../../img/TCP_transport_chemii.jpg'

/* Texts */
import texts from '../../constants/texts'
import paths from '../../constants/paths'

export default ({ locale }) => {
  return (
    <div className='main-container'>
      <section className='cover imagebg height-70 padding' data-overlay='5'>
        <div
          className='background-image-holder'
          style={{
            background: `url(${background_1})`,
            opacity: 1
          }}
        >
          <img alt='background' src={background_1} />
        </div>
        <div className='container pos-vertical-center'>
          <div className='row'>
            <div className='col-sm-9 col-md-8'>
              <h1>
                <b>{texts[locale].TCP.section_1.title}</b>
              </h1>
              <p className='lead'>{texts[locale].TCP.section_1.paragraph}</p>
            </div>
          </div>
        </div>
      </section>
      <section className='feature-large-14 switchable padding switchable--switch bg--dark'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='boxed boxed--border boxed--lg'>
                <div className='row'>
                  <div className='col-sm-10 col-sm-offset-1'>
                    <div className='slider text-center' data-paging='true'>
                      <ul className='slides'>
                        <li>
                          <img alt='Image' src={standardy} />
                          <h5>{texts[locale].TCP.section_2.title}</h5>
                          <p>{texts[locale].TCP.section_2.paragraph}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-5'>
              <div className='switchable__text'>
                <h3>
                  <b>{texts[locale].TCP.section_3.title}</b>
                </h3>
                <p className='lead'>
                  {texts[locale].TCP.section_3.paragraph_1}
                  <br />
                  <br />
                  {texts[locale].TCP.section_3.paragraph_2}
                  <br />
                </p>
                <hr className='short' />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='switchable feature-large bg--primary padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6'>
              <img
                alt='Image'
                className='border--round box-shadow-wide'
                src={transport_chemii}
              />
            </div>
            <div className='col-sm-6 col-md-5'>
              <div className='switchable__text'>
                <h2>
                  <b>{texts[locale].TCP.section_4.title}</b>
                </h2>
                <p className='lead'>{texts[locale].TCP.section_4.paragraph}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='text-center bg--primary space--sm padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6 col-md-3'>
              <div className='text-block'>
                <i className='icon color--dark icon-Geo-Close icon--lg' />
                <h4>{texts[locale].TCP.section_4.icons.icon_1}</h4>
              </div>
            </div>
            <div className='col-sm-6 col-md-3'>
              <div className='text-block'>
                <i className='icon color--dark icon-Administrator icon--lg' />
                <h4>{texts[locale].TCP.section_4.icons.icon_2}</h4>
              </div>
            </div>
            <div className='col-sm-6 col-md-3'>
              <div className='text-block'>
                <i className='icon color--dark icon-Add-File icon--lg' />
                <h4>{texts[locale].TCP.section_4.icons.icon_3}</h4>
              </div>
            </div>
            <div className='col-sm-6 col-md-3'>
              <div className='text-block'>
                <i className='icon color--dark icon-Truck icon--lg' />
                <h4>{texts[locale].TCP.section_4.icons.icon_4}</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='imagebg space--sm padding' data-overlay='4'>
        <div
          className='background-image-holder'
          style={{
            background: `url(${background_2})`,
            opacity: 1
          }}
        >
          <img alt='background' src={background_2} />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='cta cta--horizontal text-center-xs'>
              <div className='col-sm-4'>
                <h4>
                  <b>{texts[locale].TCP.section_5.title}</b>
                </h4>
              </div>
              <div className='col-sm-4'>
                <p className='lead'>{texts[locale].TCP.section_5.paragraph}</p>
              </div>
              <div className='text-right text-center-xs col-sm-2'>
                <AniLink
                  duration={1}
                  cover
                  direction='up'
                  bg='#230d54'
                  to={`${paths[locale]['/about/'].path}#${
                    texts[locale].links.contact
                  }`}
                  className='btn btn--primary type--uppercase'
                >
                  <span className='btn__text'>
                    {texts[locale].links.contact_us}
                  </span>
                </AniLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
